<template>
  <div id="profileEdit">
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="12">
            <profile-form-edit :user="user"></profile-form-edit>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
  import ProfileFormEdit from './components/ProfileFormEdit'

  export default {
    name: 'Profile',
    props: ['user'],
    components: {ProfileFormEdit}
  }
</script>
