<template>
  <div id="profile-form-edit">
    <b-card>
      <b-media no-body>
        <b-media-aside>
          <b-link>
            <b-avatar badge
              size="40"
              variant="light-secondary"
              :text=nameAvatar
              class="badge-minimal"
              badge-variant="success"/>
          </b-link>
        </b-media-aside>
        <b-media-body class="mt-75 ml-75">
          <span class="mb-75 mr-75">{{emailUser}}</span>
        </b-media-body>
      </b-media>
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group
                label="Nombre"
                label-for="name">
              <b-form-input
                v-model="profileForm.name"
                name="username"
                :state="nameErrors.length > 0 ? false:null"
                @input="$v.profileForm.name.$touch()"/>
                <small class="text-danger">{{ nameErrors[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
                label="Apellido"
                label-for="last_name">
              <b-form-input
                v-model="profileForm.lastName"
                name="last_name"
                :state="lastnameErrors.length > 0 ? false:null"
                @input="$v.profileForm.lastName.$touch()"/>
              <small class="text-danger">{{ lastnameErrors[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="m-1">
            <span class="border-right text-secondary">
              **Si no deseas cambiar tu contraseña, deja en blanco los siguientes campos
            </span>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Contraseña"
              label-for="password">
              <b-form-input
                v-model="profileForm.password"
                name="password"
                type="password"
                :state="passwordErrors.length > 0 ? false:null"
                @input="$v.profileForm.password.$touch()"/>
              <small class="text-danger">{{ passwordErrors[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Confirmar Contraseña"
              label-for="confirm_password">
              <b-form-input
                v-model="profileForm.passwordConfirm"
                name="last_name"
                type="password"
                :state="passwordConfirmErrors.length > 0 ? false:null"
                @input="$v.profileForm.passwordConfirm.$touch()"/>
              <small class="text-danger">{{ passwordConfirmErrors[0] }}</small>
            </b-form-group>
          </b-col>
        </b-row>
        
        

        <b-row align-h="end">
        <div class="d-flex align-items-end justify-content-end mr-1 mt-1">
          <b-form-group>
            <div>
              <b-overlay
                :show="overlayForm"
                opacity="0.5"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
                @hidden="_onHidden()">
                <b-button block
                  ref="button"
                  :disabled="overlayForm"
                  variant="primary"
                  @click.prevent="_editUserProfile()">
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50"/>
                  <span class="align-middle">
                  Guardar
                  </span>
                </b-button>
              </b-overlay>
            </div>
          </b-form-group>
        </div>
      </b-row>


      </b-form>
    </b-card>
  </div>
</template>

<script>
  import {mapActions, mapState} from 'vuex'
  import {minLength, required, sameAs} from 'vuelidate/lib/validators'
  import ToastificationContent from "@core/components/toastification/ToastificationContent";

  export default {
    name: 'ProfileEditForm',
    props: ['user'],
    data(){
      return {
        overlayForm: false,
        profileForm: {
          user_id: '',
          name: '',
          lastName: '',
          email: '',
          password: '',
          passwordConfirm: '',
        }, 
      }
    },
    validations: {
      profileForm: {
        name: {
          required,
        },
        lastName: {
          required
        },
        password: {
          minLengthCustom : function(value) {
            if(value != ''){
              return value.length > 10;
            }else{
              return true;
            }
          },
          containsUppercase: function(value) {
            if(value != ''){
              return /[A-Z]/.test(value)
            }else{
              return true;
            }
          },
          containsLowercase: function(value) {
            if(value != ''){
              return /[a-z]/.test(value)
            }else{
              return true;
            }
          },
          containsNumber: function(value) {
            if(value != ''){
              return /[0-9]/.test(value)
            }else{
              return true;
            }
          },
          containsSpecial: function(value) {
            if(value != ''){
              return /[#?!@$%^&*-]/.test(value)
            }else{
              return true;
            }
          }
        },
        passwordConfirm: {
          sameAsPassword: sameAs('password')
        }
      },
    },
    beforeMount () {
      this.profileForm.lastName = this.$store.state.profile.profile.last_name;
      this.profileForm.name = this.$store.state.profile.profile.name;
      this.profileForm.email = this.$store.state.profile.profile.email;
      this.profileForm.user_id = this.$store.state.profile.profile.id;
    },
    computed: {
      nameErrors () {
        const errors = []
        if (!this.$v.profileForm.name.$dirty) return errors
        !this.$v.profileForm.name.required && errors.push('El nombre es obligatorio')
        return errors
      },
      lastnameErrors () {
        const errors = []
        if (!this.$v.profileForm.lastName.$dirty) return errors
        !this.$v.profileForm.lastName.required && errors.push('El apellido es obligatorio')
        return errors
      },
      passwordErrors () {
        const errors = []
        if (!this.$v.profileForm.password.$dirty) return errors
        !this.$v.profileForm.password.minLengthCustom && errors.push('La contraseña debe tener mínimo 10 caracteres')
        !this.$v.profileForm.password.containsUppercase && errors.push('La contraseña debe tener al menos una letra mayuscula')
        !this.$v.profileForm.password.containsLowercase && errors.push('La contraseña debe tener al menos una letra minuscula')
        !this.$v.profileForm.password.containsNumber && errors.push('La contraseña debe tener al menos un numero')
        !this.$v.profileForm.password.containsSpecial && errors.push('La contraseña debe tener al menos un caracter especial')
        return errors
      },
      passwordConfirmErrors () {
        const errors = []
        if (!this.$v.profileForm.passwordConfirm.$dirty) return errors
        !this.$v.profileForm.passwordConfirm.sameAsPassword && errors.push('Las contraseñas no coinciden')
        return errors
      },
      emailUser() {
        return this.$store.state.profile.profile.email ? this.$store.state.profile.profile.email : ''
      },
      nameAvatar () {
        return this.$store.state.profile.profile.name ? this.$store.state.profile.profile.name.substr(0,1) : ''
      }
    },
    methods: {
      ...mapActions('profile',['editUserProfile']),
      ...mapActions('profile',['fetchProfile']),
      ...mapActions('auth',['signOut','signIn']),
      
      async _signOut () {
        await this.signOut()
          .then(res =>{ 
            this.$router.push({path: '/'}).catch(err => {console.info(err.message)})
          }).catch(err => {
            this.$router.push({path: '/'}).catch(err => {console.info(err.message)})
          }).finally(() => {
            this.$PermissionHelper.detroySession();
          });
      }, 
      async _editUserProfile () {
        this.$v.profileForm.$touch()
        if (!this.$v.profileForm.$invalid) {
        this.overlayForm = true
        var self = this;
        
        this.profileForm.email = this.$store.state.profile.profile.email;

        await this.editUserProfile(this.profileForm)
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Perfil modificado con éxito',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
            this.$store.state.profile.profile.name = this.profileForm.name
            this.$store.state.profile.profile.last_name = this.profileForm.lastName
            
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Problemas al modificar perfil',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }).finally(() => {
            this.overlayForm = false
          })
        }
      }, 
      async _signIn () { 
            this.overlayForm = true
            await this.signIn(this.profileForm)
            .then(ressigin => {
              this.fetchProfile()
              .then(res => { 

                this.$PermissionHelper.detroySession();
                this.$PermissionHelper.setPermissions(res.data.me.permissions);
                this.$PermissionHelper.setRole(res.data.me.roles); 
                this.$root.$emit('fetch-profile-post-actions',res);

              })
            }).catch(err => {
              this._messageErrorAuth(err.status)
              this.$toast({
                component: ToastificationContent,
                  props: {
                    title: this.errorAuth,
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                  },
              })
            }).finally(() => {
              this.overlayForm = false
            }); 
      },
      _onHidden() {
        this.$refs.button.focus()
      },
    }
  }
</script>
